import { default as S } from './MainContentSamsungCardBanner.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContentSamsungCardBanner({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-samsung-card-banner" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/samsung-card-banner/1.png`}
          alt="삼성카드 배너"
          onClick={() => {
            window.open(
              'https://s.wink.co.kr/marketing/events/html/2024/ch/sscard_t2_LD3.html',
              '_blank'
            );
          }}
        />
      </S.Background>
    </S.Section>
  );
}
