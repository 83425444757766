import { GetServerSidePropsContext } from 'next';
import { getRedirectPageForLegacy } from '@/utils/miscUtils';
import MainPage from '@/features/main/MainPage';

export async function getServerSideProps(context: GetServerSidePropsContext) {
    const query = context.query;
    const destination = getRedirectPageForLegacy(
        query as Record<string, string>
    );

    if (destination) {
        return {
            redirect: {
                destination,
            },
            props: {},
        };
    }

    return {
        props: {},
    };
}

const HomePage = ({ history }: { history: any }) => {
    return <MainPage history={history} />;
};

export default HomePage;
