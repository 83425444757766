'use client';
import React, { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { service } from '../configs/service';
import moment from 'moment';

const COOKIE_NAME = '__WINK_PC_WEB::POP_UP_DONT_SHOW_AGAIN';
const DATA = [
    {
        DATE_RANGE: ['2025-01-14', '2025-01-17'],
        CONTENT: {
            BG: {
                src: 'https://s.wink.co.kr/marketing/popup/pc/pc-popup-body-250114.png',
                style: { width: 550 },
            },
            CLOSE_BTN: {
                src: 'https://s.wink.co.kr/marketing/popup/pc/pc-popup-close-btn-250114.png',
                style: {
                    position: 'absolute',
                    width: 43,
                    top: 10,
                    right: 10,
                    cursor: 'pointer',
                },
            },
            DONT_SHOW_AGAIN_TODAY_BTN: {
                fontStyle: {
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: 14,
                },
                style: {
                    position: 'absolute',
                    right: 30,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                },
            },
        },
    },
];

export const CustomPopup = () => {
    const element =
        typeof window !== 'undefined' && document.getElementById('wrap')!;

    const currentPopup = DATA.find(({ DATE_RANGE }) => {
        const [startDate, endDate] = DATE_RANGE;
        return moment().isBetween(moment(startDate), moment(endDate));
    });

    const [showPopup, setShowPopup] = useState(!!currentPopup);

    useLayoutEffect(() => {
        checkCookie();
    }, []);

    const checkCookie = () => {
        const dontShowDate = service.getCookie(COOKIE_NAME);
        const today = moment();
        if (moment(dontShowDate).isSame(today, 'day')) {
            setShowPopup(false);
        }
    };

    const handleDontShowAgainToday = () => {
        setShowPopup(false);
        service.setCookie({
            name: '__WINK_PC_WEB::POP_UP_DONT_SHOW_AGAIN',
            value: moment().format('YYYY-MM-DD'),
            expireDate: currentPopup?.DATE_RANGE[1],
        });
    };
    const onClose = () => {
        setShowPopup(false);
    };

    if (!showPopup || !currentPopup) return null;

    return element
        ? createPortal(
              <Overlay>
                  <ModalContainer>
                      <div
                          style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%,-50%)',
                          }}
                      >
                          <img
                              style={currentPopup.CONTENT.BG.style}
                              src={currentPopup.CONTENT.BG.src}
                              alt="popup"
                          />
                          <img
                              style={
                                  currentPopup.CONTENT.CLOSE_BTN
                                      .style as React.CSSProperties
                              }
                              src={currentPopup.CONTENT.CLOSE_BTN.src}
                              alt="clost-btn"
                              onClick={onClose}
                          />
                          <div
                              style={
                                  currentPopup.CONTENT.DONT_SHOW_AGAIN_TODAY_BTN
                                      .style as React.CSSProperties
                              }
                              onClick={handleDontShowAgainToday}
                          >
                              <input
                                  type="checkbox"
                                  name="dontShowAgainToday"
                              />
                              <label
                                  className="transparent-checkbox"
                                  htmlFor="dontShowAgainToday"
                                  style={
                                      currentPopup.CONTENT
                                          .DONT_SHOW_AGAIN_TODAY_BTN.fontStyle
                                  }
                              >
                                  오늘 하루 열지 않기
                              </label>
                          </div>
                      </div>
                  </ModalContainer>
              </Overlay>,
              element
          )
        : null;
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    position: relative;
`;
