import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
  cursor: pointer;
`;

const StyledMainContentSamsungCardBanner = {
  Section,
  Background,
};

export default StyledMainContentSamsungCardBanner;
