import SimpleMainContent from './SimpleMainContent';
import MainContent0 from './MainContent0';
import MainContent1 from './MainContent1';
import MainContent2 from './MainContent2';
import MainContent3 from './MainContent3';
import MainContent4 from './MainContent4';
import MainContent6 from './MainContent6';
import MainContent8 from './MainContent8';
import MainContent13 from './MainContent13';
import MainContent15 from './MainContent15';
import MainContent17 from './MainContent17';
import MainContent23 from './MainContent23';
import MainContent24 from './MainContent24';
import MainContent26 from './MainContent26';
import MainContent28 from './MainContent28';
import MainContent30 from './MainContent30';
import MainContent32 from './MainContent32';
import MainContent34 from './MainContent34';
import MainContent35 from './MainContent35';
import MainContentSamsungCardBanner from './MainContentSamsungCardBanner';

export {
  SimpleMainContent,
  MainContent0,
  MainContent1,
  MainContent2,
  MainContent3,
  MainContent4,
  MainContent6,
  MainContent8,
  MainContent13,
  MainContent15,
  MainContent17,
  MainContent23,
  MainContent24,
  MainContent26,
  MainContent28,
  MainContent30,
  MainContent32,
  MainContent34,
  MainContent35,
  MainContentSamsungCardBanner,
};
